<template>
  <div>
    <div v-if="loading">Loading...</div>

    <div v-if="error" class="dark-red">{{ error }}</div>
    <form
      v-on:submit.prevent="resetPassword(password, reEnterPassword)"
      class="measure tl"
    >
      <fieldset class="ba b--transparent ph0 mh0">
        <div>
          <!-- password -->
          <label class="db fw6 lh-copy f6" for="password">
            {{ $t("reset_password.password") }}
          </label>
          <input
            name="password"
            id="password"
            class="pa2 input-reset ba bg-transparent w-100"
            type="password"
            v-model="password"
            placeholder
          />
          <!-- re-enter password -->
          <label class="db fw6 lh-copy f6" for="reEnterPassword">{{
            $t("reset_password.re_enter_password")
          }}</label>
          <input
            name="reEnterPassword"
            id="reEnterPassword"
            class="pa2 input-reset ba bg-transparent w-100"
            type="password"
            v-model="reEnterPassword"
            placeholder
          />
        </div>
      </fieldset>
      <button
        class="w-100 f6 fw6 dim dib ttu tracked b white bg-black link tc ph3 pv2 mb2 lh-copy roboto sans-serif"
        :disabled="!password || !reEnterPassword"
      >
        {{ this.translation }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "reset-password",
  props: {
    token: String,
    translation: String
  },
  data() {
    return {
      password: null,
      reEnterPassword: null
    };
  },
  methods: {
    resetPassword(password, reEnterPassword) {
      this.$store.dispatch("auth/resetPassword", {
        token: this.token,
        password: password,
        reEnterPassword: reEnterPassword,
        router: this.$router,
        language: this.$route.params.lang
      });
    },
    isTokenValid() {
      this.$store.dispatch("auth/verifyResetPasswordToken", {
        token: this.token
      });
    }
  },
  mounted() {
    this.isTokenValid();
  },
  created() {},
  computed: {
    ...mapGetters("auth", {
      error: "error",
      loading: "loading"
    })
  },
  watch: {
    $route: "isTokenValid"
  }
};
</script>
