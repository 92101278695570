<template>
  <section class="w-100 f6 mt0-l mt4 ph4-l ph3">
    <article class="bg-white mh5-l mh0">
      <div class="ph5-l ph4 pv3">
        <div class="tc">
          <div class="ba b--light-gray bw2">
            <h1 class="f3 f2-ns ttu vidaloka">
              {{ $t("order.activation_title") }}
            </h1>
          </div>
        </div>
        <article class="karla tracked-light lh-copy">
          <p class="tc">{{ $t("order.activation_article") }}</p>
          <reset-password
            class="measure center tl"
            :token="this.$route.params.token"
            :translation="$t('reset_password.activate')"
          />
        </article>
      </div>
    </article>
  </section>
</template>

<script>
import ResetPassword from "../components/ResetPassword.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "new-account",
  components: {
    "reset-password": ResetPassword
  },
  methods: {},
  computed: {},
  mounted() {},
  created() {}
};
</script>

<style lang="stylus"></style>
